<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              {{ displayText("partner.partner_area", "合作夥伴專區") }}
            </b-breadcrumb-item>

            <b-breadcrumb-item active v-if="!isVerifyPage">{{
              displayText("partner.partner_worker_management", "夥伴員工管理")
            }}</b-breadcrumb-item>
            <b-breadcrumb-item active v-if="isVerifyPage">{{
              displayText("partner.partner_worker_verify_page", "夥伴員工審核")
            }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div
        class="
          d-flex
          flex-column flex-xl-row
          align-items-start align-items-xl-center
          justify-content-between
          mb-4 mb-xl-2
        "
      >
        <h4
          class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold"
          v-if="!isVerifyPage"
        >
          {{ displayText("partner.partner_worker_management", "夥伴員工管理") }}
        </h4>
        <h4
          class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold"
          v-if="isVerifyPage"
        >
          {{
            displayText("partner.partner_worker_verify_page", "夥伴員工審核")
          }}
        </h4>
        <div
          class="
            col-12 col-xl-8
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="
              checkPermission([consts.PARTNER_WORKER_VERIFY]) && isVerifyPage
            "
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="approved"
          >
            {{
              displayText("partner.partner_worker_verify_approved", "審核成功")
            }}
          </b-button>
          <b-button
            v-if="
              checkPermission([consts.PARTNER_WORKER_VERIFY]) && isVerifyPage
            "
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="danger"
            @click="rejected"
          >
            {{
              displayText("partner.partner_worker_verify_rejected", "審核失敗")
            }}
          </b-button>
          <b-button
            v-if="
                checkPermission([consts.PARTNER_WORKER_CREATE]) && !isVerifyPage
            "
             class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
             variant="primary"
             @click="exportPartnerWorker()"
             >{{ displayText('partner.export_partner_worker', '匯出夥伴員工') }}
          </b-button>
          <b-button
            v-if="
              checkPermission([consts.PARTNER_WORKER_CREATE]) && !isVerifyPage
            "
            v-b-modal.modal-upload
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            ><i class="fa fa-plus"></i
            >{{
              displayText("partner.partner_worker_import", "批次匯入夥伴員工")
            }}</b-button
          >
          <b-button
            v-if="
              checkPermission([consts.PARTNER_WORKER_CREATE]) && !isVerifyPage
            "
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            :to="partnerWorkerCreateRoute"
            ><i class="fa fa-plus"></i
            >{{
              displayText("partner.partner_worker_add", "新增夥伴員工")
            }}</b-button
          >

          <b-form-select
            class="mr-3 mb-2 mb-xl-0"
            v-model="query.partner_id"
            :options="partners"
            @change="getPartnerWorkers"
            :disabled="isDropdownDisabled"
          ></b-form-select>

          <b-input-group class="ml-0">
            <b-form-input
              v-bind:placeholder="
                displayText(
                  'partner.worker_search_placeholder',
                  '請輸入姓名、手機號碼、email、員工編號'
                )
              "
              v-model="query.keyword"
              v-on:keyup.enter="getPartnerWorkers()"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="getPartnerWorkers()"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button
            v-b-toggle.collapse-1-inner
            variant="link"
            class="flex-shrink-0 ml-0 ml-xl-1"
          >
            {{ displayText("partner.worker_search_advanced", "進階搜尋")
            }}<i class="fa fa-chevron-down"></i>
          </b-button>
        </div>
      </div>
      <b-collapse id="collapse-1-inner" class="mt-2">
        <div class="border p-2">
          <b-form>
            <div class="d-flex flex-column flex-xl-row">
              <b-form-group
                v-if="hiddenSearchField('worker_name')"
                v-bind:label="displayText('partner.worker_name', '員工姓名')"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-lg="9"
                style="width: 100%"
              >
                <b-form-input v-model="query.name" class="mr-2"></b-form-input>
              </b-form-group>

              <b-form-group
                v-bind:label="displayText('partner.title', '合作夥伴')"
                label-cols-sm="3"
                label-cols-lg="2"
                style="width: 100%"
                v-if="hiddenSearchField('partner_id')"
              >
                <b-form-select
                  class="mr-3 mb-2 mb-xl-0"
                  v-model="query.partner_id"
                  :options="partners"
                  @change="getPartnerWorkers"
                  :disabled="isDropdownDisabled"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="d-flex flex-column flex-xl-row">
              <b-form-group
                v-bind:label="displayText('partner.worker_sales', '業務員')"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-lg="9"
                style="width: 100%"
                v-if="hiddenSearchField('staff_name')"
              >
                <b-form-input
                  v-model="query.staff_name"
                  class="mr-2"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="hiddenSearchField('job_specialty')"
                v-bind:label="
                  displayText('partner.worker_job_specialty_name', '服務科室')
                "
                label-cols-sm="3"
                label-cols-lg="2"
                style="width: 100%"
              >
                <b-form-input
                  v-model="query.job_specialty"
                  class="mr-2"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="d-flex flex-column flex-xl-row">
              <!-- 狀態 -->
              <b-form-group
                v-if="hiddenSearchField('status')"
                v-bind:label="
                  displayText('partner.worker_verify_status', '審核狀態')
                "
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-lg="9"
                style="width: 100%"
              >
                <b-form-select
                  v-model="query.status"
                  :options="statusOptions"
                  class="mr-2 form-control form-control-sm"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-if="hiddenSearchField('dept_name')"
                v-bind:label="
                  displayText('partner.worker_dept_name', '部門名稱')
                "
                label-cols-sm="3"
                label-cols-lg="2"
                style="width: 100%"
              >
                <b-form-input
                  v-model="query.dept_name"
                  class="mr-2"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="d-flex flex-column flex-xl-row">
              <!-- 是否綁定Line -->
              <b-form-group
                v-bind:label="
                  displayText('partner.worker_line_bound', '是否綁定Line帳號')
                "
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-lg="9"
                style="width: 100%"
                v-if="hiddenSearchField('line_bound')"
              >
                <b-form-radio-group v-model="query.line_bound">
                  <b-form-radio :value="0" class="mr-2">全部</b-form-radio>
                  <b-form-radio :value="1" class="mr-2">綁定</b-form-radio>
                  <b-form-radio :value="-1" class="mr-2">未綁定</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>

            <div class="flex-column">
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0"
                @click="handleSearch"
                ><i class="fa fa-search"></i
                >{{ displayText("partner.search", "搜尋") }}</b-button
              >
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0"
                @click="resetSearch"
                >{{
                  displayText("partner.search_reset", "重置搜尋條件")
                }}</b-button
              >
            </div>
          </b-form>
        </div>
      </b-collapse>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="partnerWorkers"
                :fields="fields"
              >
                <template #head(checkbox)>
                  <b-form-checkbox
                    v-model="selectAll"
                    @change="toggleSelectAll"
                  ></b-form-checkbox>
                </template>
                <template #cell(checkbox)="data">
                  <b-form-checkbox
                    v-model="data.item.selected"
                  ></b-form-checkbox>
                </template>
                <template #cell(worker_name)="data">
                  <div v-if="data.item.highlight">
                    <div :style="{ 'color': data.item.highlightColor }">
                        <span>*</span> {{ data.item.name }}
                    </div>
                  </div>
                  <div v-else>
                    {{ data.item.name }}
                  </div>
                </template>
                <template #cell(staff_name)="data">
                  <div v-if="data.item.staff">
                    {{ data.item.staff.name }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    v-if="checkPermission([consts.PARTNER_WORKER_LIST_VIEW])"
                    variant="inverse-primary"
                    :to="{
                      name: 'PartnerWorkerView',
                      params: { partnerWorkerId: data.item.id },
                    }"
                  >
                    {{ displayText("partner.view", "查看") }}
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.PARTNER_WORKER_MODIFY])"
                    variant="inverse-warning"
                    @click="partnerWorkerEditPush(data.item.id)"
                  >
                    {{ displayText("partner.edit", "編輯") }}
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.PARTNER_WORKER_DELETE])"
                    variant="inverse-danger"
                    @click="deletePartnerWorker(data.item.id)"
                  >
                    {{ displayText("partner.delete", "刪除") }}
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="container" style="margin-top: 80px">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 col-md-auto mb-3">
              <span>目前第 {{ currentPage }} 頁 / 總共 {{ Math.ceil(totalRows / query.per_page) }} 頁</span>
            </div>

            <div class="col-12 col-md-auto mb-3">
              <b-pagination
                class="my-auto"
                v-if="totalRows > 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="query.per_page"
              ></b-pagination>
            </div>

            <div class="col-12 col-md-auto mb-3">
              <span>每頁顯示數量：</span>
              <b-form-select
                v-model="query.per_page"
                :options="perPageOption"
                class="d-inline-block"
                style="width: auto;"

              ></b-form-select>
              <span> / 總數量: {{ totalRows }}</span>
            </div>
          </div>
        </div>

      </b-card-text>
    </b-card>
    <b-modal id="modal-upload" title="批次上傳">
      <b-overlay :show="showLoadingUpload">
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0">請上傳帳號 Excel 檔案</label>
          <b-form-file plain @input="uploadPartnerWorker"></b-form-file>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="
            getSampleExcelFile(
              'partner_worker_import_xls',
              '/excel/partner_worker_list_v2.xlsx'
            )
          "
          ><i class="fa fa-file-text"></i>下載範例檔案</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import partnerWorkerApi from "@/apis/partner-worker";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import { displayText, getSampleExcelFile } from "@/utils/dashboardUtils";
import moment from "moment";

export default {
  data() {
    return {
      consts: consts,
      perPageOption: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      statusOptions: [
        { text: displayText("partner.all", "全部"), value: null },
        {
          text: displayText("partner.status_line_init", "前台新增人員"),
          value: "line_init",
        },
        {
          text: displayText("partner.status_dashboard_init", "後台新增人員"),
          value: "dashboard_init",
        },
        {
          text: displayText("partner.status_pending", "待審核"),
          value: "pending",
        },
        {
          text: displayText("partner.status_line_init", "前台新增人員"),
          value: "line_init",
        },
        {
          text: displayText("partner.status_dashboard_init", "後台新增人員"),
          value: "dashboard_init",
        },
        {
          text: displayText("partner.status_approved", "審核通過"),
          value: "approved",
        },
        {
          text: displayText("partner.status_rejected", "審核失敗"),
          value: "rejected",
        },
      ],
      selectAll: false,
      currentPage: 1,
      initialized: false,
      totalRows: 0,
      showLoading: true,
      showLoadingUpload: false,
      partners: [
        {
          value: null,
          text: displayText("partner.select_partner", "選擇夥伴公司"),
        },
      ],
      partnerWorkers: [],
      categoryOptions: [],
      priorityOptions: [],
      partnerWorker: null,
      query: {
        per_page: 10,
        partner_id: null,
        keyword: "",
        page: 1,
        name: null,
        status: null,
        dept_name: null,
        staff_name: null,
        line_bound: 0,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      dashboardModule: (state) => state.dashboardModule,
    }),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    fields() {
      let fields = [
        {
          key: "worker_name",
          label: displayText("partner.worker_name", "員工姓名"),
        },
        {
          key: "mobile_phone",
          label: displayText("partner.mobile", "手機號碼"),
        },
        {
          key: "partner_name",
          label: displayText("partner.partner_name", "合作夥伴名稱"),
        },
        {
          key: "job_specialty",
          label: displayText("partner.worker_job_specialty", "科室"),
        },
        {
          key: "job_title",
          label: displayText("partner.worker_job_title", "職稱"),
        },
        {
          key: "staff_name",
          label: displayText("partner.worker_staff_name", "業務"),
        },
        {
          key: "priority",
          label: displayText("partner.worker_priority", "重點客戶"),
          formatter: (value) => {
            if (value) {
              if (
                this.priorityOptions.find((options) => options.value === value)
              ) {
                return this.priorityOptions.find(
                  (options) => options.value === value
                ).text;
              } else {
                return "無法顯示";
              }
            }
          },
        },
        {
          key: "category",
          label: displayText("partner.worker_category", "客戶分類"),
          formatter: (value) => {
            if (value) {
              if (
                this.categoryOptions.find((options) => options.value === value)
              ) {
                return this.categoryOptions.find(
                  (options) => options.value === value
                ).text;
              } else {
                return "無法顯示";
              }
            }
          },
        },
        {
          key: "status",
          label: displayText("partner.status", "狀態"),
          formatter: (value) => {
            if (value === "approved") {
              return displayText("partner.status_approved", "審核通過");
            } else if (value === "pending") {
              return displayText("partner.status_pending", "待審核");
            } else if (value === "rejected") {
              return displayText("partner.status_rejected", "審核失敗");
            }  else if (value === "dashboard_init") {
              return displayText("partner.status_dashboard_init", "後台新建人員");
            }  else if (value === "line_init") {
              return displayText("partner.status_line_init", "前台新建人員");
            }
          },
        },
        {
          key: "is_enabled",
          label: displayText("partner.is_enabled", "是否啟用"),
          formatter: (value) => {
            return value
              ? displayText("partner.enabled", "啟用")
              : displayText("partner.disabled", "未啟用");
          },
        },
        {
          key: "actions",
          label: displayText("partner.action", "操作"),
        },
      ];
      if (
        this.isVerifyPage &&
        this.checkPermission([this.consts.PARTNER_WORKER_VERIFY])
      ) {
        fields.unshift({
          key: "checkbox",
        });
      }
      const hiddenFields = this.getModuleConfig("partner", "partner_worker.hidden_fields")
      if (hiddenFields) {
        return fields.filter(field => !hiddenFields.includes(field.key))
      }
      return fields
    },
    isDropdownDisabled() {
      return !!this.$route.params.partnerId;
    },
    isVerifyPage() {
      return this.$route.query?.page_type === "verify";
    },
    partnerWorkerCreateRoute() {
      if (!this.query.partner_id) {
        return { name: "PartnerWorkerCreate" };
      }

      const route = {
        name: "PartnerWorkerCreateByPartner",
        params: { partnerId: this.query.partner_id },
      };

      if (this.$route.params.partnerId) {
        route.query = { disablePartnerSelect: true };
      }

      return route;
    },
    queryWatcher() {
      return `${this.query.keyword}-${this.query.page}-${this.query.per_page}-${this.query.partner_id}`;
    },
  },
  watch: {
    currentPage: function (newPage, oldPage) {
      if (!this.initialized || newPage === oldPage) return;
      this.query.page = newPage;
    },
    isVerifyPage: {
      handler(value) {
        if (value) {
          this.query.status = "pending";
          this.query.page_type = "verify";
        } else {
          this.query.status = null;
          this.query.page_type = null;
        }
        this.getPartnerWorkers();
      },
    },
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        this.updateUrl();
        this.getPartnerWorkers();
      },
      deep: true,
    },
  },
  async created() {
    const newQuery = {
      ...this.query,
      ...this.$route.query,
    };

    if (newQuery.page) {
      newQuery.page = parseInt(newQuery.page, 10);
    } else {
      newQuery.page = 1;
    }

    this.query = newQuery;

    if (this.$route.params.partnerId)
      this.query.partner_id = this.$route.params.partnerId;

    this.$store.commit("route/SET_QUERY_PARAMS", this.query);

    this.currentPage = newQuery.page;
  },
  async mounted() {
    this.setOptions();
    await this.getPartnerWorkers();
    await this.fetchPartner();
    this.initialized = true;
  },
  methods: {
    displayText,
    getSampleExcelFile,
    hiddenSearchField(fieldName) {
      if (this.getModuleConfig("partner", "partner_worker.hidden_fields")) {
        return !this.getModuleConfig("partner", "partner_worker.hidden_fields").includes(fieldName);
      } else {
        return true
      }
    },
    async exportPartnerWorker() {
      let query = this.query;
      const filteredQuery = Object.keys(query).reduce((acc, key) => {
        if (
          query[key] !== null &&
          query[key] !== undefined &&
          query[key] !== ""
        ) {
          acc[key] = query[key];
        }
        return acc;
      }, {});
      partnerWorkerApi.exportPartnerWorker(this.organization, filteredQuery).then(() => {
        this.$swal({
          title: '成功',
          text: "請至資料匯出查看結果",
          type: 'success',
          showCancelButton: true,
          confirmButtonText: '前往查看',
          cancelButtonText: '確認'
        }).then((result)=>{
            if (result.value) {
              this.$router.push({
                name: "MyConsoleDownloadFileList",
                params: { org_code: this.$route.params.org_code },
              });
            } else {
              return;
            }
          });
      })
    },
    partnerWorkerEditPush(id) {
      const route = {
        name: "PartnerWorkerEdit",
        params: { partnerWorkerId: id },
      };

      if (this.$route.params.partnerId) {
        route.query = { disablePartnerSelect: true };
      }

      return this.$router.push(route);
    },
    setOptions() {
      if (this.getModuleConfig("partner", "partner_worker.partner_worker_category")) {
        this.categoryOptions =
          this.getModuleConfig("partner", "partner_worker.partner_worker_category")
            .sort((a, b) => a.order - b.order)
            .map((c) => {
              return {
                text: c.text,
                value: c.key,
              };
            });
      }
      if (this.getModuleConfig("partner", "partner_worker.partner_worker_priority")) {
        this.priorityOptions =
          this.getModuleConfig("partner", "partner_worker.partner_worker_priority")
            .sort((a, b) => a.order - b.order)
            .map((c) => {
              return {
                text: c.text,
                value: c.key,
              };
            });
      }
    },
    toggleSelectAll() {
      this.partnerWorkers.forEach((item) => (item.selected = this.selectAll));
    },
    async approved() {
      let payload = {};
      payload.partner_worker_ids = this.partnerWorkers
        .filter((item) => item.selected)
        .map((item) => item.id);
      if (payload.partner_worker_ids.length == 0) {
        this.$swal.fire({
          title: "審核失敗",
          type: "warning",
          text: "至少請勾選一名對象",
        });
        return
      }
      this.showLoading = true;
      try {
        await partnerWorkerApi.verifyPartnerWorkerApproved(
          this.organization,
          payload
        );
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "審核通過",
        });
        this.getPartnerWorkers();
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "更新失敗",
            type: "error",
            text: error.response.data.message,
          });
        }
      }
      this.showLoading = false;
    },
    async rejected() {
      let payload = {};
      payload.partner_worker_ids = this.partnerWorkers
        .filter((item) => item.selected)
        .map((item) => item.id);
      if (payload.partner_worker_ids.length == 0) {
        this.$swal.fire({
          title: "審核失敗",
          type: "warning",
          text: "至少請勾選一名對象",
        });
        return
      }
      this.showLoading = true;
      try {
        await partnerWorkerApi.verifyPartnerWorkerRejected(
          this.organization,
          payload
        );
        this.$swal.fire({
          title: "完成",
          type: "success",
          text: "審核不通過",
        });
        this.getPartnerWorkers();
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "更新失敗",
            type: "error",
            text: error.response.data.message,
          });
        }
      }
      this.showLoading = false;
    },
    async getPartnerWorkers() {
      this.showLoading = true;
      try {
        let params = {
          ...this.query,
        };

        const { data } = await partnerWorkerApi.getPartnerWorkers(
          this.organization,
          params
        );
        const highlightConfig = this.getModuleConfig("partner", "partner_worker.highlight");
        this.partnerWorkers = data.data.map((worker) => {
          if (highlightConfig) {
            worker.highlight = false;
            worker.highlightColor = highlightConfig.color;
            highlightConfig.config.forEach((condition) => {
              let checkBox = 0
              Object.entries(condition).forEach(([key, value]) => {
                // 時間格式的判斷
                if (key.includes("_at")) {
                  if (worker[key]) {
                    if (moment().subtract(value, 'days').isBefore(moment(worker[key]))) {
                      checkBox++;
                    }
                  }
                } else {
                  if (worker[key] === value) {
                    checkBox++;
                  }
                }
              });
              if (checkBox === Object.entries(condition).length) {
                worker.highlight = true;
              }
            });
          }
          return {
            ...worker,
            selected: false,
          };
        });
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: displayText("partner.api_failed", "讀取資料錯誤"),
          });
        }
      }
      this.showLoading = false;
    },
    async deletePartnerWorker(partnerWorkerId) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await partnerWorkerApi.removePartnerWorker(
              this.organization,
              partnerWorkerId
            );
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除",
              });
              await this.getPartnerWorkers();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    async fetchPartner() {
      if (this.partners.length > 1) return;

      try {
        let params = {
          order_by: "partner_code",
        };
        const { data } = await partnerWorkerApi.getPartnerList(
          this.organization,
          params
        );
        data.data.forEach((partner) => {
          this.partners.push({
            text: partner.partner_code + " " + partner.name,
            value: partner.id,
          });
        });
      } catch (error) {
        console.error(error);
        this.$swal(
          "錯誤",
          displayText("partner.api_failed", "讀取資料錯誤"),
          "error"
        );
      }
    },
    async uploadPartnerWorker(file) {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", file);

      try {
        let response = await partnerWorkerApi.uploadPartnerWorker(
          this.organization,
          formData
        );

        if (response.status === 200 && response.data.status === "success") {
          this.$swal.fire({
            title: "上傳成功",
            type: "success",
          });

          this.query.page = 1;
          await this.getPartnerWorkers();
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 422 && e.response.data.message) {
          this.$swal.fire({
            title: "檔案內容有誤",
            type: "error",
            text: e.response.data.message ?? null,
          });
        } else {
          this.$swal.fire({
            title: "上傳失敗",
            type: "error",
          });
        }
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-upload");
      }
    },
    handleSearch() {
      this.updateUrl();
      this.getPartnerWorkers();
    },
    resetSearch() {
      this.query = {
        per_page: 10,
        partner_id: this.$route.params.partnerId || null,
        keyword: "",
        page: 1,
        name: null,
        status: null,
        dept_name: null,
        staff_name: null,
        line_bound: 0,
      };
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    updateUrl() {
      let query = this.query;
      const filteredQuery = Object.keys(query).reduce((acc, key) => {
        if (
          query[key] !== null &&
          query[key] !== undefined &&
          query[key] !== ""
        ) {
          acc[key] = query[key];
        }
        return acc;
      }, {});
      this.$store.commit("route/SET_QUERY_PARAMS", filteredQuery);
      this.$router.push({ path: "", query: filteredQuery }).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
    },
  },
};
</script>
<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
